import React, { useState } from "react";
import { db } from "../Firebase";
import { doc, getDoc } from "firebase/firestore";
import { TbCalendarTime } from "react-icons/tb";
import { useUserAuth } from "../contexts/UserAuthContext";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons";
import { KickoffForm, Scheduler } from ".";
import AiIcon from "./AiIcon";
import { Highlight } from "react-instantsearch-hooks-web";
import { Button, ActionIcon, Group, Modal, Spoiler, Center, Card, Tooltip, Anchor, Stepper } from "@mantine/core";
import { HiOutlineLockClosed, HiQuestionMarkCircle } from "react-icons/hi";
import { TiCreditCard } from "react-icons/ti";
import BananaOn from "../components/Buttons/Bananas/BananaOn";
import BananaOff from "../components/Buttons/Bananas/BananaOff";
import { IoChevronBack } from "react-icons/io5";

const ResultHits = ({ hit }) => {
  // State to manage Modal popup
  const [modalOpened, setModalOpened] = useState(false);
  // State to manage the two steps of the get started form
  const [step, setStep] = useState(1);
  const [active, setActive] = useState(1);

  // is close date classified as TBD
  const isTBD = hit.close_date.slice(-4) == "2099";

  // function for setting next state
  function handleNextStep() {
    setStep(step + 1);
    setActive((current) => (current < 2 ? current + 1 : current));
  }

  // function for setting th previous state
  function handlePreviousStep() {
    setStep(step - 1);
    setActive((current) => (current > 0 ? current - 1 : current));
  }

  const AuthContext = useUserAuth();

  // This function either appends or deletes the item from the users favorites
  async function modifyUserFavorite(objectID, title) {
    const user = AuthContext.user;
    if (user) {
      const bidDocRef = doc(db, "bids", objectID);
      const bidDocSnap = await getDoc(bidDocRef);
      const bidData = bidDocSnap.data();

      // Create a deep, mutable copy of the favorites state and use it to modify the user favorites
      let favoritesCopy = structuredClone(AuthContext.favorites);

      if (objectID in favoritesCopy) {
        delete favoritesCopy[objectID];
        showNotification({
          title: "Favorite removed",
          icon: <IconX size={18} />,
          autoClose: 3000,
          message: `Removed "${title}" from favorites!`,
          color: "red",
        });
      } else {
        favoritesCopy[objectID] = bidData;
        showNotification({
          title: "Favorite added",
          icon: <IconCheck size={18} />,
          autoClose: 3000,
          message: `Added "${title}" to favorites!`,
          color: "green",
        });
      }
      AuthContext.setUpdateFavorites(favoritesCopy);
    }
  }

  // This calls the asynchronous modifyUserFavorite function
  const favoritesOnClick = (objectID, title) => {
    modifyUserFavorite(objectID, title);
  };

  const kickoffBidOnClick = () => {
    if (AuthContext.user) {
      setModalOpened(true);
    }
  };

  // If user is logged in, provide responsive CSS functionality to the favorites button
  let favoritesBanana;
  let isFavorited = false;
  if (AuthContext.favorites != null && Object.keys(AuthContext.favorites).length != 0) {
    isFavorited = hit.objectID in AuthContext.favorites;
    favoritesBanana = isFavorited ? <BananaOn /> : <BananaOff />;
  } else {
    favoritesBanana = <BananaOff />;
  }

  return (
    <div className="sm:flex flex-col items-start tour-card">
      <div className="justify-center md:max-w-4xl  md:self-stretch">
        <article className="break-words whitespace-pre-line flex-nowrap mb-10 mx-3 border-1 border-gray-200 rounded-xl drop-shadow-sm max-w-4xl tour-card">
          <Group position="apart" className="flex-nowrap items-start">
            <span className=" flex p-2 pt-4 ml-3 font-semibold flex-wrap sm:flex-nowrap">
              Title:&nbsp;&nbsp;
              <p className=" sm:max-w-3xl">
                <Highlight
                  attribute="title"
                  hit={hit}
                  classNames={{
                    root: "pl-0 font-light xs:max-w-xs xl:max-w-lg md:max-w-lg",
                    nonHighlighted: "",
                    highlighted: "bg-gradient-to-l from-leaf-lightest-green to-bb-yellow",
                  }}
                />
              </p>
            </span>
            <div className="top-0 sm:flex sm:flex-col tour-favorite">
              <div className="flex flex-row justify-end">
                <div className="inline-flex pr-3">
                  <Tooltip
                    label={`${isFavorited ? "Remove from favorites" : "Add to favorites"}`}
                    withArrow
                    position="top"
                    offset={5}
                    openDelay={600}
                    transition="pop"
                    transitionDuration={200}
                    color="bb-blue"
                  >
                    <ActionIcon
                      variant="transparent"
                      size="xl"
                      color="gray.4"
                      radius="lg"
                      id="FavoritesButton"
                      onClick={function (e) {
                        e.preventDefault();
                        favoritesOnClick(hit.objectID, hit.title);
                      }}
                      className="mt-2"
                    >
                      {favoritesBanana}
                    </ActionIcon>
                  </Tooltip>
                </div>
              </div>
            </div>
          </Group>
          {AuthContext.user.admin && (
            <span className="flex p-2 ml-3 font-semibold flex-wrap">
              Site Name:&nbsp;&nbsp; <p className="pl-0 font-light">{hit.site_name}</p>
            </span>
          )}
          <span className="flex p-2 ml-3 font-semibold flex-wrap">
            Agency:&nbsp;&nbsp;{" "}
            <span className="max-w-md sm:max-w-[33rem] pl-0 font-light">
              <p className=" sm:max-w-3xl">
                <Highlight
                  attribute="agency"
                  hit={hit}
                  classNames={{
                    root: "pl-0 font-light xs:max-w-xs xl:max-w-lg md:max-w-lg",
                    nonHighlighted: "",
                    highlighted: "bg-gradient-to-l from-leaf-lightest-green to-bb-yellow",
                  }}
                />
              </p>
            </span>
          </span>
          <span className="flex p-2 ml-3 font-semibold flex-wrap">
            State:&nbsp;&nbsp; <p className="pl-0 font-light">{hit.state}</p>
          </span>
          <span className="flex p-2 ml-3 font-semibold">
            Open Date: <p className="max-w-3xl pl-2 font-light">{hit.open_date}</p>
          </span>
          <span className="flex p-2 ml-3">
            <div className="flex font-semibold">
              Close Date: <p className="pl-2 font-light">{isTBD ? "TBD " : hit.close_date}</p>
            </div>
            <div>
            {isTBD && (
              <Tooltip
                label="The close date is either continuous or unknown at this time."
                allowPointerEvents
                withArrow
                multiline
                width={240}
                position="right"
                openDelay={100}
                transition="pop"
                transitionDuration={200}
                color="bb-blue"
              >
                <p className="ml-2">
                  <HiQuestionMarkCircle size={23} className="text-gray-400" />
                </p>
              </Tooltip>
            )}
            </div>
          </span>
          <span className="break-words max-w-sm sm:max-w-2xl tour-description">
            <span className="pl-2 ml-3 font-semibold">Description: </span>
            <span className="flex px-0 mr-3 text-justify">
              <Spoiler
                maxHeight={48}
                showLabel="Show more"
                hideLabel="Hide"
                className=" max-w-[22rem] lg:max-w-3xl md:max-w- sm:max-w-[33rem]  self-stretch xs:break-words sm:break-words flex-column -indent-5 whitespace-normal mr-3 p-2 ml-3 font-light justify-center"
              >
                <Highlight
                  attribute="description"
                  hit={hit}
                  classNames={{
                    root: "text-justify p-2 ml-3 font-light",
                    nonHighlighted: "text-justify",
                    highlighted: "bg-gradient-to-l from-leaf-lightest-green to-bb-yellow",
                  }}
                />
              </Spoiler>
            </span>
          </span>

          {/* kickoff form */}

          <Modal
            centered
            overlayColor="gray"
            overlayOpacity={0.55}
            overlayBlur={3}
            size="xl"
            closeOnEscape
            opened={modalOpened}
            onClose={() => setModalOpened(false)}
            onSubmit={() => setModalOpened(true)}
          >
            <div className="m-2 p-2 ">
              {/* Progress stepper */}
              <Stepper
                active={active}
                onStepClick={setActive}
                breakpoint="sm"
                color="cyan"
                classNames={{
                  root: "font-Lexend-Deca-Regular ",
                  stepIcon: "font-Lexend-Deca-Regular",
                  stepCompletedIcon: "font-Lexend-Deca-Regular ",
                  stepLabel: "font-Lexend-Deca-Regular",
                  stepDescription: "font-Lexend-Deca-Regular",
                }}
              >
                <Stepper.Step label="Step 1" description="Confirm selection" className="font-Lexend-Deca-Regular"></Stepper.Step>
                <Stepper.Step label="Step 2" description="Schedule meeting" className="font-Lexend-Deca-Regular"></Stepper.Step>
              </Stepper>
            </div>
            {/* Conditional rendering state for which component to show */}
            {step === 1 ? <KickoffForm handleClose={() => setModalOpened(false)} title={hit.title} url={hit.url} handleNextStep={handleNextStep} /> : <Scheduler handleClose={() => setStep(0)} />}
            {step === 1 ? (
              <div id="button container" className="flex justify-end"></div>
            ) : (
              <div id="button container" className="flex justify-start">
                <Button variant="default" className="border-1 border-[#425b76] text-[#425b76] font-Lexend-Deca-Regular" leftIcon={<IoChevronBack />} onClick={handlePreviousStep}>
                  Back
                </Button>
              </div>
            )}
          </Modal>

          {/* Bottom left card links */}
          <Card.Section>
            <div className="flex justify-center sm:flex sm:flex-col z-1 relative sm:justify-end pr-1 mr-2 my-2 pb-3">
              <div>
                <Group position="apart" spacing="md">
                  <span className="flex mx-3 pl-2 ">
                    <div className={hit.ai_enhanced ? "visible" : "hidden"}>
                      <Tooltip
                        label="Some data for this bid has been extracted using AI"
                        withArrow
                        multiline
                        position="bottom"
                        width={185}
                        openDelay={400}
                        transition="pop"
                        transitionDuration={200}
                        color="bb-blue"
                        zIndex={99}
                      >
                        <p className="mr-4">
                          <AiIcon size={24} className="text-gray-400" />
                        </p>
                      </Tooltip>
                    </div>
                    <div className={hit.login_required ? "visible" : "hidden"}>
                      <Tooltip label="This bid requires an additional login" withArrow multiline position="bottom" width={240} openDelay={300} transition="pop" transitionDuration={200} color="bb-blue">
                        <p className="mr-4">
                          <HiOutlineLockClosed size={24} className="text-gray-400" />
                        </p>
                      </Tooltip>
                    </div>
                    <div className={hit.payment_required ? "visible" : "hidden"}>
                      <Tooltip label="This bid requires additional payment" withArrow multiline position="bottom" width={240} openDelay={300} transition="pop" transitionDuration={200} color="bb-blue">
                        <p className="mr-4">
                          <TiCreditCard size={26} stroke={1.5} className="text-gray-400" />
                        </p>
                      </Tooltip>
                    </div>
                    <div className={new Date(hit.open_date) > new Date() ? "visible" : "hidden"}>
                      <Tooltip label="This is a forecasted opportunity and the details of this bid may change over time." withArrow multiline position="bottom" width={270} openDelay={300} transition="pop" transitionDuration={200} color="bb-blue">
                        <p className="">
                          <TbCalendarTime size={24} className="text-gray-400" />
                        </p>
                      </Tooltip>
                    </div>
                  </span>
                  <Group spacing="md" className="m-2 mr-5">
                    <Center>
                      <Button
                        // component='a'
                        // href={hit.url}
                        // target="_blank"
                        // rel="noopener noreferrer"
                        variant="default"
                        size="sm"
                        radius="xl"
                        className=" hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-white border:black duration-900 text-gray-600 font-normal tour-link"
                      >
                        <Anchor href={hit.url} target="_blank" className="text-gray-600 font-normal hover:text-gray-600 no-underline hover:no-underline">
                          Link to bid
                        </Anchor>
                      </Button>
                    </Center>
                    <Center>
                      <Button
                        onClick={() => kickoffBidOnClick()}
                        variant="outline"
                        size="sm"
                        radius="xl"
                        className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black tour-getStarted"
                      >
                        Get started!
                      </Button>
                    </Center>
                  </Group>
                </Group>
              </div>
            </div>
          </Card.Section>
        </article>
      </div>
    </div>
  );
};

export default ResultHits;
