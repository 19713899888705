import { List } from "@mantine/core";
import React from "react";
import { BananaBanner } from "../components";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";

const Termsofuse = () => {
  useAbleCDPTracking();
  return (
    <div id="wrapper" className="h-fit m-auto flex-grow flex-col w-full relative justify-center ">
      <BananaBanner title1="Terms" title2="of use" />
      <div className="flex justify-center">
        <div className="flex justify-center max-w-sm mx-5 sm:max-w-4xl">
          <main className="text-justify flex-col justify-center">
            <h1>The Bid Banana TERMS OF USE</h1>
            <br />
            <h2 className="font-semibold">Definitions</h2>
            <section>
              <List withPadding>
                <List.Item>
                  (a) "Affiliate" means, with respect to an entity, any person or entity that directly or indirectly owns, is owned by, or is under common ownership with that entity. For purposes of
                  this definition, ownership means control of more than a 50% interest in an entity.
                </List.Item>
                <List.Item>(b) "End User" means the users of your Application.</List.Item>
                <List.Item>(c) "Services" means the documentation that BidBanana makes available under these TOU.</List.Item>
              </List>
            </section>

            <br />
            <h2 className="font-semibold">Acceptance of Terms </h2>
            <section>
              <span>
                Bid Lab Technologies LLC, operates and owns the website www.BidBanana.TheBidLab.com (the “Site” or “Bid Banana”). This Agreement provides the general terms and conditions (the “Terms
                of Use”) applicable to all services (the “Services”) offered by Bid Banana to you through its website. By accessing or using this Site, you accept without limitations or qualifications
                to be bound by the Terms of Use. Bid Banana reserves the right to change the Terms of Use from time to time at its sole discretion. Your use of the Site will be subject to the most
                current version of the Terms of Use posed on the Site at the time of such use. In addition, when using particular Services or the material posted on this Site (“Materials''), or
                entering sections of the Site reserved strictly for members of Bid Banana, you shall be subject to any terms and conditions set out in policies of Bid Banana posted on the Site,
                including, without limitation, the Privacy Policy and other posed guidelines or rules applicable to such Services or Materials that may contain terms and conditions in addition to
                those in this Agreement. All such policies, guidelines, or rules applicable to such Services or materials that may contain terms and conditions in addition to those in this Agreement.
                All such policies, guidelines, or rules are hereby incorporated by reference into this Agreement. If you breach any of the Terms of Use, Bid Banana may, in addition to terminating your
                access to use this Site, limit your activity, immediately remove your item listings, warn other members of your actions, issue a warning, temporarily suspend, indefinitely suspend or
                terminate your subscription and refuse to provide services to you. This Agreement is void where prohibited by law, and the right to access the Site and Materials is revoked in such
                jurisdictions. Understanding these terms is important because, by using our services, you are agreeing to these terms. Bid Banana maintains this Site for your use and grants you the
                permission to use and view the Site subject to these Terms of Use.
              </span>
            </section>

            <br />
            <h2 className="font-semibold">Registration and Account Creation </h2>
            <section>
              <span>
                As a part of the registration and account creation process, you are required to select a User ID and a password. You will provide Bid Banana with certain registration information, all
                of which must be accurate and updated from time to time. You will be solely responsible for maintaining the confidentiality of your password. Also, you are solely responsible for all
                usage or activity on your Bid Banana account including, but not limited to, use of the account by any person authorized by you to use your User ID and password.
              </span>
            </section>

            <br />
            <h2 className="font-semibold">Non-Transferability of Subscription</h2>
            <section>
              <span>Your right to use this site is personal to you and you may not sub-license, transfer, sell or assign this agreement to a third party without our approval.</span>
            </section>

            <br />
            <h2 className="font-semibold">Registration information</h2>
            <section>
              <span>
                As a part of the registration and account creation process, you are required to select a User ID and a password, as well as your company name and email address. You will provide
                certain registration information to Bid Banana, all of which must be accurate and updated from time to time. You will be solely responsible for maintaining the confidentiality of your
                password. Also you are solely responsible for all usage or activity on your BidBanana account including, but not limited to use of the account by any person authorized by you to use
                your User ID and Password.{" "}
              </span>
            </section>

            <br />
            <h2 className="font-semibold">Subscription Renewal and Cancellation </h2>
            <section>
              <span>
                Bid Banana will automatically charge your account upon the renewal date on which you subscribed, depending on the subscription plan you chose during enrollment. Please note that you
                give Bid Banana permission to do so. You will be provided access to our service immediately after your payment has been successfully processed. In the event that you would like to
                discontinue service, please click the 'Manage Bid Banana Subscription' button within the 'Billing' section of Bid Banana's 'Account Settings' page, which is located on the top right
                drop-down menu of Bid Banana. If a subscriber decides to cancel during the subscription period, the remaining portion of the subscription fee will NOT be refunded. After cancellation,
                your membership will remain fully active until the upcoming payment due date of your account.
              </span>
            </section>

            <br />
            <h2 className="font-semibold">Disclaimer of Warranties </h2>
            <section>
              <span>
                You expressly understand and agree that :
                <br />
                <List withPadding>
                  <List.Item>
                    a. Your use of the service is at your sole risk. The service is provided on an "as is" and "as available" basis. BidBanana expressly disclaims all warranties of any kind, whether
                    expressed or implied, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement.
                  </List.Item>
                  <List.Item>
                    b. BidBanana makes no warranty that (i) the service will meet your requirements, (ii) the service will be uninterrupted, timely, secure, or error-free, (iii) the results that may
                    be obtained from the use of the service will be accurate or reliable, (iv) the quality of any products, services, information, or other material purchased or obtained by you
                    through the service will meet your expectations, (v) any errors in the software will be corrected, (vi) or that this web site, its content, and the servers on which the web site
                    and content are available are free of viruses or other harmful components.
                  </List.Item>
                  <List.Item>
                    c. Any material downloaded or otherwise obtained through the use of the service is done at your own discretion and risk and that you will be solely responsible for any damage to
                    your computer system or loss of data that results from the download of any such material.
                  </List.Item>
                  <List.Item>
                    d. No advice or information, whether oral or written, obtained by you from BidBanana or through or from the service shall create any warranty not expressly stated in the TOU.
                  </List.Item>
                  <List.Item>
                    e. This web site may contain various combinations of text, images, audiovisual productions, opinions, statements, facts, articles, market data, stock quotes or other information
                    created by BidBanana or by third-parties. Due to the number of sources from which content in this site is obtained, and the inherent hazards of electronic distribution, there may
                    be delays, omissions or inaccuracies in such content. Accordingly, such content, including the market data, is for your reference only and should not be relied upon by you for any
                    purpose. Such content is not intended for the purpose of tax or investment advice and it does not advocate the purchase or sale of any security or investment.
                  </List.Item>
                  <List.Item>
                    f. Information created by third parties that you may access on the site or through links is not adopted or endorsed by BidBanana and remains the responsibility of such third
                    parties.
                  </List.Item>
                </List>
              </span>
            </section>

            <br />
            <h2 className="font-semibold">Liability</h2>
            <section>
              <span>
                You expressly agree and understand that Bid Banana is not liable for any inconsistencies in data. Further, we are not liable for any direct, indirect, incidental, special,
                consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses, (even if Bid Banana has been advised of
                the possibility of such damages), resulting from: (i) the use or inability to to use the service; (ii) the cost of procurement of substitute goods or services resulting from any goods,
                data, information or services obtained or messages received or transactions entered into through or from the service; (iii) unauthorized access to or alteration of your transmissions
                or data; (iv) statements or conduct of any third party on the service; or (v) any other matter relating to the service.{" "}
              </span>
            </section>

            <br />
            <h2 className="font-semibold">Trademark Information</h2>
            <section>
              <span>
                Bid Banana, Search, Favorites, Get Expert Help, Upload, and other BidBanana logos and product and service names are trademarks of Bid Banana. All other trademarks appearing on the Site
                are trademarks of their respective owners and our reference to them does not imply or indicate any approval or endorsement by their owners unless such approval or endorsement is
                expressly made. It is understood that Bid Banana is a news and information site and service and that references to other names and trademarks are necessary in the course of providing
                news and commentary about the subjects that this site covers. Bid Banana will enforce its intellectual property rights to the fullest extent of the law. If you suspect that Bid Banana
                content or trademarks are being misused, please contact our customer support team as soon as possible.
              </span>
            </section>

            <br />
            <h2 className="font-semibold">Non-Waiver</h2>
            <section>
              <span>
                The failure of Bid Banana to exercise or enforce any right or provision of the TOU shall not constitute a waiver of such right or provision. If any provision of the TOU is found by a
                court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision,
                and the other provisions of the TOU remain in full force and effect. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or
                related to use of the Service or the TOU must be filed within one (1) year after such claim or cause of action arose or be forever barred.
              </span>
            </section>

            <br />
            <h2 className="font-semibold">Violations </h2>
            <section>
              <span>
                We reserve the right to deal with your breach of this Agreement, your misuse of the Services, and/or infringement on someone else’s rights with our own legal defense however we see
                fit. Our protection rights shall include all costs associated with the Claim or Claims, including attorneys’ fees, court costs, fees associated with collection, and/or dispute
                resolution costs.
                <br />
                <br />
                Ways that violate applicable law for the Use the Services include:
                <List withPadding>
                  <List.Item>I. Illegal activities, such as piracy or violating copyright, trademark, or other intellectual property laws; </List.Item>
                  <List.Item>II. Threatening, defaming, defrauding, degrading, victimizing, or intimidated anyone for any reason;</List.Item>
                  <List.Item>III. Violating privacy laws and regulations.</List.Item>
                </List>
                <br />
                We reserve the right to modify these Terms of Use by posting a revised version of Terms of Use on our website. You are responsible for reviewing the terms and becoming familiar with
                any modifications. We shall not be in default under any provision of this Agreement or be liable for any delay, failure of performance or interruption in services, including our
                Software, resulting, directly or indirectly, that are reasonably beyond our control.
                <br />
                <br />
                You must comply with all laws and regulations applicable to you and End User’s use of the Services, including laws related to privacy, security, data protection, and U.S. export laws.
                You may not assign or delegate any rights or obligations in the Terms of Use herein, including connection with a change of control, without prior written consent from Bid Lab
                Technologies LLC. We may also freely assign or delegate any rights and obligations in the Terms of Use, fully or partially, without notice.
                <br />
                <br />
                The Terms of Use herein are solely for your benefit and ours. It is not for the benefit of any third party, person, except for permitted successors or assigns.
                <br />
                <br />
                These terms herein are effective upon your acceptance. Either party may terminate these terms of use if the other party is in breach of any section in this agreement. In the event of
                termination by Bid Lab Technologies LLC, due to any breach, all rights granted to you by these terms will automatically terminate and you will cease to have any rights to use the
                Services. Additionally, if you are licensing the Services under a Limited License, Terms of Use may be terminated at any time without notice.
                <br />
              </span>
            </section>

            <br />
            <h2 className="font-semibold">Indemnity </h2>
            <section>
              <span>
                {" "}
                You shall, at your own expense, indemnify, hold, and defend Bid Banana and its shareholders, subsidiaries, affiliates, officers, employees, agents, co-branders, associates, suppliers,
                or other partners harmless from and against any losses, costs, damages, liabilities, and expenses (including reasonable attorneys’ fees), arising out of or related to a third party
                claim, action or allegation (a) related in any way to a transaction or dispute between you and another Member or between you and any third party, (b) based on or caused by unauthorized
                access to the Site through Member’s account, or (c) is based on facts or alleged facts that would constitute a breach of any of Member’s representations, warranties, or covenants under
                this Agreement. You shall not enter into a settlement of the foregoing without Bid Banana’s prior consent, and shall not incur expenses and fees in defending the above claims, actions,
                or allegations, without prior approval from Bid Banana. By using the Site you acknowledge and agree the Bid Banana has no control over the quality, safety, legality or accuracy of the
                content found on the Site. As a result, you shall release Bid Banana and its shareholders, subsidiaries, affiliates, officers, employees, agents, co-branders, associates, suppliers, or
                other partners from claims, demands, and damages (actual and consequential) of every kind and nature, known and unknown, suspected or unsuspected, disclosed and undisclosed, arising
                out of or in any way connected with your use of the content found on the Site.{" "}
              </span>
            </section>

            <br />
            <h2 className="font-semibold">Entire Agreement</h2>
            <section>
              <span>
                This Agreement constitutes the entire agreement between you and Bid Banana with respect to the Site and supersedes all prior agreements and discussions previously made (written or
                oral) between you and Bid Banana, subject to any specific agreement governing Services subscribed by you through this Site. This agreement may be modified only by a written and signed
                agreement, or as follows: Bid Banana may modify this Agreement by posting the new terms and conditions in the same location the previous terms and conditions were posted. If you
                continue to use the Materials, Services, or Site, then you will be considered to have accepted the modifications.
                <br />
                <br />
                All rights to the Services and the Content, including rights of use, not specifically granted herein are reserved by Bid Lab Technologies LLC.
              </span>
            </section>
            <br />
            <br />
          </main>
        </div>
      </div>
    </div>
  );
};

export default Termsofuse;
